/* eslint-disable no-var */
// This file contains code that should be executed before any Vue-related code
// Use ES5 syntax to support IE9-IE11 obsolete browser message
var isIE =
  typeof window.isIE9OrLess !== 'undefined' ||
  // IE 10 (Adapted from https://stackoverflow.com/a/17076008/1409101)
  // eslint-disable-next-line no-new-func
  Function('/*@cc_on return document.documentMode===10@*/')() ||
  // IE 11 (Adapted from https://stackoverflow.com/a/21825207)
  (!!window.MSInputMethodContext && !!document.documentMode);

// Adapted from https://stackoverflow.com/a/65282911/1409101
var isEdgeLegacy = navigator.userAgent.indexOf('Edge/') > -1;

// Determine if the browser is obsolete
if (isIE || isEdgeLegacy) {
  import(
    /* webpackChunkName: "preload-warning" */
    '@/styles/preload-warning.scss'
  );
} else {
  import(
    /* webpackChunkName: "app" */
    /* webpackPrefetch: true */
    '@/main.js'
  );
}
